/* =================================
    ANIMATIONS SCSS 
================================= */

@keyframes grow-transp {
    from {
        opacity: 0.8;
        transform: scale(1);
   }
    to {
        opacity: 0;
        transform: scale(4);
   }
}

@keyframes l15-1 {
    0%,
    10%  {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateY(0deg) }
    33%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(0deg) }
    66%  {transform:scale(var(--s,1)) translate(-10px) perspective(150px) rotateX(-180deg)}
    90%,
    100% {transform:scale(var(--s,1)) translate(0px)   perspective(150px) rotateX(-180deg)}
}
@keyframes l15-2 {
    0%,49.99% {background: $primary-color}
    50%,100%  {background: $secondary-color}
}