/*!
*    ______ _       _   _
*    |  ____| |     | | (_)
*    | |__  | | __ _| |_ _  ___ ___  _ __
*    |  __| | |/ _` | __| |/ __/ _ \| '_ \
*    | |    | | (_| | |_| | (_| (_) | | | |
*    |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*         https://www.flaticon.com
*/

@font-face {
    font-family: "flaticon_nexacom";
    src: url("../fonts/flaticon_nexacom.woff2?a0550457e42c1c91541d454859e092fd") format("woff2"),
url("../fonts/flaticon_nexacom.woff?a0550457e42c1c91541d454859e092fd") format("woff"),
url("../fonts/flaticon_nexacom.svg?a0550457e42c1c91541d454859e092fd#flaticon_nexacom") format("svg")
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_nexacom !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-liste-de-contacts:before {
    content: "\f101";
}
.flaticon-avatar-de-lutilisateur:before {
    content: "\f102";
}
.flaticon-message:before {
    content: "\f103";
}
.flaticon-case:before {
    content: "\f104";
}
.flaticon-chat-mobile:before {
    content: "\f105";
}
.flaticon-recu:before {
    content: "\f106";
}
.flaticon-page-daccueil:before {
    content: "\f107";
}
.flaticon-fleche-vers-le-bas:before {
    content: "\f108";
}
.flaticon-interface-du-tableau-de-bord:before {
    content: "\f109";
}
.flaticon-plus:before {
    content: "\f10a";
}
.flaticon-email:before {
    content: "\f10b";
}
.flaticon-cadenas:before {
    content: "\f10c";
}
.flaticon-il:before {
    content: "\f10d";
}
.flaticon-il-1:before {
    content: "\f10e";
}
.flaticon-fleche-droite:before {
    content: "\f10f";
}
.flaticon-se-deconnecter:before {
    content: "\f110";
}
.flaticon-cadenas-editer:before {
    content: "\f111";
}
.flaticon-prochain:before {
    content: "\f112";
}
.flaticon-campagne-sociale:before {
    content: "\f113";
}
.flaticon-loupe:before {
    content: "\f114";
}
.flaticon-suivi:before {
    content: "\f115";
}
.flaticon-cocher:before {
    content: "\f116";
}
.flaticon-crayon:before {
    content: "\f117";
}
.flaticon-poubelle:before {
    content: "\f118";
}
.flaticon-il-2:before {
    content: "\f119";
}
.flaticon-appel:before {
    content: "\f11a";
}
.flaticon-avion-en-papier:before {
    content: "\f11b";
}
.flaticon-calendrier:before {
    content: "\f11c";
}
.flaticon-sourire:before {
    content: "\f11d";
}
.flaticon-email-1:before {
    content: "\f11e";
}
.flaticon-menu:before {
    content: "\f11f";
}
.flaticon-euro:before {
    content: "\f120";
}
.flaticon-annuler-levenement:before {
    content: "\f121";
}
.flaticon-question-et-reponse:before {
    content: "\f122";
}
.flaticon-ajouter-un-utilisateur:before {
    content: "\f123";
}
.flaticon-blacklist-un-utilisateur:before {
    content: "\f124";
}
.flaticon-tache:before {
    content: "\f125";
}
.flaticon-repondre-a-tous:before {
    content: "\f126";
}
.flaticon-fleche-vers-le-haut:before {
    content: "\f127";
}
.flaticon-question-un-utilisateur:before {
    content: "\f128";
}
.flaticon-chat-a-bulles:before {
    content: "\f129";
}

