.tabulator-1,
.tabulator-1 .tabulator-header,
.tabulator-1 .tabulator-header .tabulator-col,
.tabulator-1 .tabulator-row .tabulator-cell,
.tabulator .tabulator-footer {
    border: none;
}

.tabulator-1 {
    background-color: transparent;
    border: 1px dashed rgba(106, 113, 133, 0.3) !important;
}

.tabulator-1 .tabulator-header {
    border-bottom: 1px dashed rgba(106, 113, 133, 0.3) !important;
}

.tabulator-1 .tabulator-header .tabulator-col .tabulator-col-content,
.tabulator-1 .tabulator-row .tabulator-cell {
    padding: 12px 20px;
}
.tabulator-1 .tabulator-row .tabulator-cell {
    border-bottom: 1px dashed rgba(106, 113, 133, 0.3) !important;
}
.tabulator-1 .tabulator-header .tabulator-col {
    background-color: #f1f1f1;
}

.tabulator-1 .tabulator-row.tabulator-row-even {
    background-color: #f1f1f1;
}

.tabulator-1 .tabulator-row .tabulator-responsive-collapse {
    border-top: none;
}
.tabulator-1 .tabulator-row .tabulator-responsive-collapse > table > tr > td {
    padding-bottom: 5px;
} 

.tabulator-1  .tabulator-row.tabulator-selectable:hover {
    background-color: #dcdcdc;
}


.tabulator-1 .tabulator-footer {
    background-color: transparent;
}

.tabulator-1 .tabulator-footer .tabulator-page {
    border-color: $primary-color;
    border-radius: 5px;
    color: $primary-color;
    padding: 5px 10px;
    transition: .4s;

    &.active {
        color: $primary-color;
    }

    &:not(disabled):hover {
        background: $primary-color;
        color: #fff;
    }
}