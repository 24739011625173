/* =================================
    OTHERS SCSS 
================================= */

.action__delete a {
    color: $danger-color;
    i { font-size: 25px !important; line-height: 0 !important; }
}
.action__black a {
    color: $dark-color;
    i { font-size: 25px !important; line-height: 0 !important; }
}
.action__edit a {
    color: $tertiary-color;
    i { font-size: 25px !important; line-height: 0 !important; }
}
.action__default a {
    color: $primary-color;
    i { font-size: 25px !important; line-height: 0 !important; }
    &:hover { color: $secondary-color }
}
.action__view a {
    color: $primary-color;
    i { font-size: 25px !important; line-height: 0 !important; }
    &:hover { color: $secondary-color }
}
.action li a {
    padding: 7px;
}

#alert-container {
    top: 100px;
    right: 5px;
    position: fixed;
    z-index: 9999999;
}

.alert [data-notify=icon],
.alert [data-notify=title] {
    display: none;
}
.alert .progress {
    height: 2px !important;
}

.alert-custom {
    border: 1px dashed transparent;
    border-radius: 5px;
    display: inline-block;
    font-weight: bold;
    padding: 15px 25px;

    &--warning {
        background-color: $warning-color-white;
        border-color: $warning-color-light;
        &, * { color: $warning-color; }
        p { font-weight: normal; }
    }
}

.blacklisted {
    color: $dark-color;
    opacity: .5;
}

.btn-wrap {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 10px;

    .btn-align-right {
        margin-left: auto;
    }
}
.btn-wrap--end {
    justify-content: flex-end;
}

.card-dashed {
    border: 1px dashed rgba(106, 113, 133, 0.3);
    border-radius: 5px;
    padding: 20px;
}

.dropdown .dropdown-toggle:disabled {
    background-color: transparent;
    border: 2px solid rgba($primary-color, .1);
    opacity: .4;
}

.form-floating > .form-control, 
.form-floating > .form-control-plaintext, 
.form-floating > .form-select {
    height: auto;
    min-height: 0;
}
.form-floating > label {
    white-space: normal;
}

.icon-fix {
    display: inline-block !important;
    transform: translateY(2px) !important;
}
.icon-fix-reversed {
    display: inline-block !important;
    transform: translateY(2px) scaleX(-1) !important;
}
.icon-rotate-top {
    display: inline-block !important;
    transform: translate(2px, 2px) rotate(-90deg);
}

.icon-lg {
    font-size: 22px;
    line-height: 1;
}

.input-width-1 {
    max-width: 400px;
}

.loader-wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}
.loader__load {
    width: 80px;
    height: 40px;
    display: flex;
    &::before,
    &::after {
      content: "";
      flex: 1;
      clip-path: polygon(0 0,100% 0,100% 100%);
      background: $primary-color;
      animation: 
        l15-1 1s infinite linear alternate,
        l15-2 2s infinite linear -.5s;
    }
    &::after {
      --s:-1,-1;
    }
}
.loader__text {
    color: $secondary-color;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.link-underline {
    display: inline-block;
    position: relative;
}
.link-underline::before {
    content: "";
    background-color: $primary-color;
    opacity: .2;
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 100%;
    transform: skewX(-20deg);
    transition: width .5s, background-color .5s;
}
.link-underline::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 0;
    transform: skewX(-20deg);
    transition: width .5s, background-color .5s;
}
.link-underline:hover::after {
    background-color: $primary-color;
    width: 100%;
}

.list-1 {
    li {
        margin-bottom: 8px;
        position: relative;
        padding-left: 25px;

        &::before {
            content: "\f112";
            font-family: flaticon_nexacom !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: $primary-color;
            font-size: 16px;
            position: absolute;
            top: 1px;
            left: 0;
        }
    }
}

.list-height-1 {
    overflow-y: scroll;
    max-height: 250px;
}

.list-style-lg li {
    color: $primary-color;
    font-size: 1.15rem;
    padding-left: 20px;
    position: relative;
}
.list-style-lg li::before {
    content: "\f112";
    font-family: flaticon_nexacom !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primary-color;
    font-size: 16px;
    position: absolute;
    top: .35em;
    left: 0;
}

.message-template-wrapper {
    .emoji-warning { display: none; }
    &.is-emoji-warning .emoji-warning { display: block; }
}

.modal-header {
    color: $primary-color;
}

.nav.nav-pills {
    width: 100%;
}
.nav-primary .nav-link, 
.nav-primary .show > .nav-link, 
.nav-pills.nav-primary .show > .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.pellet {
    border-radius: 5px;
    display: inline-block;
    font-weight: bold;
    padding: 15px 30px;
}
.pellet--sm {
    font-size: 14px;
    padding: 5px 10px;
}
.pellet--infos {
    background-color: $info-color;
    color: #fff;
}
.pellet--success {
    background-color: $success-color;
    color: #fff;
}
.pellet--warning {
    background-color: $warning-color;
    color: #fff;
}
.pellet-glass-1 {
    background: rgba(255, 255, 255, 0.7);
}
.pellet-glass-1 span {
   color: $secondary-color;
   font-size: 20px;
}

.pellet-icon-1 {
    background-color: $primary-color;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    padding: 5px 13px 5px 30px;
    position: relative;

    &::before {
        font-family: flaticon_nexacom !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 15px;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-53%);
    }

    &--light {
        background-color: $primary-color-white;
        color: $primary-color;
    }

    &.pellet-icon--phone::before { content: "\f11a"; }
    &.pellet-icon--mobile::before { content: "\f120"; }
}

.pellet-icon {
    --size: 25px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: var(--size);
    width: var(--size);
    i {
        font-size: 15px;
        display: inline-block;
        transform: translateY(2px);
    }
}
.pellet-icon--black {
    background-color: $dark-color;
    color: #fff;
}
.pellet-icon--sm {
    --size: 16px;
}


.quill-editor-wrapper {
    position: relative;

    .ql-container .ql-editor {
        height: 100%;
    }

    .quill-editor-1 {
        border: 1px dashed rgba(106, 113, 133, 0.3);
        border-radius: 5px;
        height: 200px;

        .embed-factor {
            background-color: $primary-color-white;
            border-radius: 5px;
            color: $primary-color;
            display: inline-block;
            padding: 2px 5px 2px 25px;
            position: relative;
            
            &::before {
                font-family: flaticon_nexacom !important;
                font-style: normal;
                font-weight: normal !important;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 7px;
                transform: translateY(-50%);
            }
        }
        .embed-factor--file::before { content: "\f116"; }
        .embed-factor--link::before { content: "\f116"; }
        .embed-factor--stop::before { content: "\f116"; }
    }

    .charcount {
        background: $primary-color-white;
        border-radius: 5px;
        display: inline-block;
        padding: 10px 25px;
        position: relative;
        z-index: 1;
        margin: -15px 0 10px 15px;

        &__feat {
            background-color: $primary-color;
            border-radius: 5px;
            color: #fff;
            display: inline-block;
            font-weight: bold;
            font-size: 13px;
            line-height: 1;
            margin-left: 6px;
            padding: 4px 6px 3px 6px;
        }
    }

}

#resume-wrapper.is-ready + .loader-wrap {
    display: none;
}

.swal-modal .swal-text {
    text-align: center;
}

.tabulator-elt-wrapper {
    table.tabulator-elt { display: none; }
    .tabulator-elt:not(.is-ready) + .loader-wrap { margin: 20px 0; }
    .tabulator-elt.is-ready + .loader-wrap { display: none; }
}


.tagify-vertical .tagify {
    display: flex;
    flex-flow: column nowrap;
    overflow-y: scroll;
    max-height: 250px;
}

// >>> HELPERS <<<
.text-note { font-size: 13px; }
.text-right { text-align: right !important; }