/**=====================
 custom CSS Start
==========================**/
// you can add your custom css here

// >>> ELEMENTS <<<
.breadcrumb {
    margin-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.page-wrapper .page-header .breadcrumb .breadcrumb-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
