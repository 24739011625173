/* =================================
    FORM INPUT 2 SCSS 
================================= */

.form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
}

.input-style-1 {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $secondary-color-white;
    border-radius: 0;
    color: $secondary-color;
    font-weight: bold;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    transition: .4s;
}
.input-style-1:focus-visible {
    border-bottom-color: $primary-color;
    outline: none;
}
.input-style-1,
.input-style-1::placeholder {
    font-size: 18px;
}

.input-icon {
    position: relative;
}
.input-icon__icon {
    color: $primary-color;
    font-size: 22px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-65%);
}
.input-icon input {
    padding-left: 35px;
}
.input-icon--2 .input-icon__icon {
    font-size: 19px;
    left: 10px;
    transform: translateY(-53%);
}


.input-password-toggle .see-password {
    cursor: pointer;
    font-size: 27px;
    line-height: 0;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    right: 10px !important;
    left: auto !important;
    transform: translateY(-40%);
    transition: .25s;
    width: 30px !important;
}
.input-password-toggle .see-password.active {
    color: $primary-color;
    opacity: 1;
}



.notif,
.outcome .error.visible {
    color: $danger-color;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    padding-left: 15px;
    position: relative;
}
.notif::before,
.outcome .error.visible::before {
    content: "";
    border-radius: 10px;
    background-color: $danger-color;
    line-height: 0;
    position: absolute;
    left: 0;
    top: 8px;
    height: 6px;
    width: 6px;
}
.notif::after,
.outcome .error.visible::after {
    content: "";
    background-color: $danger-color;
    border-radius: 4px;
    position: absolute;
    top: 9px;
    left: 1px;
    height: 4px;
    width: 4px;
    animation: grow-transp 1s ease-in-out infinite forwards;
}
.notif-form-success {
    display: flex;
    align-items: center;
    justify-content: center;
}
.notif-form-success svg {
    flex-shrink: 0;
    margin-right: 15px;
    height: 50px;
    width: 50px;
}
.notif-form-success svg > * {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    stroke: $success-color;
}
.notif-form-success svg > *:first-child {
    animation: stroke-appear-1 0.75s 0.5s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}
.notif-form-success svg > *:last-child {
    animation: stroke-appear-1 1s 1s cubic-bezier(0.5, 1, 0.89, 1) forwards;
}
.notif-form-success span {
    color: $success-color;
    font-size: 18px;
    animation: fade-in 0.5s ease-in-out forwards;
}
.notif--success {
    color: $success-color;
}
.notif--success::before,
.notif--success::after {
    background-color: $success-color;
}