/* =================================
    LOGIN 2 SCSS 
================================= */

.section-login {
    display: grid;
    grid-template-columns: 3fr 4fr;
    overflow: hidden;
    height: 100%;
}
.section-login__left {
    background: url('/storage/young-business-person-man-working-with-notebook-workplace-w1920x1080-1.webp') left center no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    isolation: isolate;

    &::before {
        content: "";
        //background: linear-gradient(-125deg, $primary-color, $secondary-color);
        background-color: $primary-color;
        mix-blend-mode: color-burn;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -5;
    }

    &::after {
        content: "";
        background: linear-gradient(-125deg, $primary-color, $secondary-color);
        opacity: .9;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -4;
    }

    .sll-overlay {
        background: url('/storage/svg/nexacom-illustration-xright-white.svg') center center no-repeat;
        background-size: cover;
        opacity: .05;
        position: absolute;
        bottom: -50px;
        left: -200px;
        height: 800px;
        width: 800px;
        rotate: -180deg;
        z-index: -1;
    }
    .sll-wrap {
        margin-bottom: 200px;
        text-align: center;
    }
    .sll-logo {
        margin-bottom: 30px;
    }
}
.section-login__right {
    position: relative;
    isolation: isolate;

    .slr-wrap {
        padding: 15vh 15px 0 15px;
        margin: 0 auto;
        max-width: 500px;
    }
    .slr-logo {
        margin-bottom: 75px;
        text-align: center;
    }
    .slr-title {
        color: $primary-color;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 50px;
        text-align: center;
    }
    .slr-overlay {
        background: url('/storage/svg/nexacom-illustration-xright.svg') center center no-repeat;
        background-size: cover;
        opacity: .05;
        position: absolute;
        top: -50px;
        right: -200px;
        height: 800px;
        width: 800px;
        z-index: -1;
    }
}

@media screen and (max-width: 992px) {

    .section-login {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-login__left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        .sll-wrap, .sll-overlay {
            display: none;
        }
    }
    .section-login__right {
        background-color: $main-body-color;
        border-radius: 10px;
        overflow: hidden;
        padding: 10vh 12vw;
        position: relative;
        z-index: 2;
        .slr-wrap {
            padding: 0;
        }
    }

}
@media screen and (max-width: 430px) {
    .section-login__right {
        padding: 10vh 25px;
    }
}